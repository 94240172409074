<template>
  <div class="container">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="message is-info">
          <div class="message-header">
            <h1>運営者情報</h1>
          </div>
          <div class="message-body">
            <div class="columns">
              <div class="column is-narrow"></div>
              <div class="column">
                <div class="columns">
                  <div class="column is-3 has-text-centered">
                    <span class="tag is-info">運営者</span>
                  </div>
                  <div class="column is-9">
                    <p class="has-text-centered">
                      <figure class="image container is-64x64 mb-2">
                        <img
                          class="is-rounded"
                          src="https://pbs.twimg.com/profile_images/1284303792523980802/rG0IQk4a_400x400.jpg"
                        />
                      </figure>
                      <span>S(エス) </span>
                      <a
                        href="https://twitter.com/goodengineer7"
                        target="_blank"
                        ><span class="tag is-white"
                          ><img
                            src="@/assets/TwitterLogoBlue.svg"
                            width="20"
                            height="20"
                          /><strong>@goodengineer7</strong></span
                        ></a
                      >
                    </p>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-3 has-text-centered">
                    <span class="tag is-info">Profile </span>
                  </div>
                  <div class="column is-9">
                    <div class="content">
                      <p>
                        フルリモートではたらくフリーランスのWebエンジニア。たまに仕事しながら「カルアミルク」を熱唱しています。
                      </p>
                      <p>
                        平日は名古屋近くにある実家兼仕事部屋でお仕事。週末は岐阜のド田舎に住む家族（奥さんと娘二人）のもとへ。（主に草刈り／雪かき要員）
                      </p>
                      <p>
                        Twitter経由で東京のスタートアップからお仕事を頂いて基本は週4日で稼働中。それ以外はPodcast配信や個人開発に取り組んだりしてます。
                      </p>
                      <p>
                        Vue.js
                        に触る機会が減ってしまったので、せっかくならとVueの新しいバージョンでこのサービスを作ってみました。
                        テストデータは Firebase / Firestore から読み込んでます。
                      </p>
                      <p>
                        「この先のキャリアをどうするか…？」って悩むより、残された人生は大して長くないので、その間に「いかにオモロイことを企画して愉しむか？どれだけたくさんの”よっしゃ！”という手応えを味わうか？」を考えるべし…そう思いつつ、のろのろと個人開発に取り組んでいます。
                      </p>
                      <p>
                        「あー楽しかった！」と笑いながら最期を迎えたいし、クリエイターであり続けたいのです。
                      </p>
                      <p>
                        ポッドキャストを配信したり、フリーランスになるいきさつを電子書籍にしてKindleに出したり、技術記事書いたり、フリーランスエンジニアのミートアップを開催してみたり、いろいろジタバタしてます。
                      </p>
                      <p>
                        もし「こやつ、面白そう…」と思っていただけたら、月に一回ぐらいのペースで近況報告メールレターを配信していますので（嘘つきました。完全にサボってます…ごめんなさいゴメンナサイ…ちゃんと配信しますー）、こちらの詳細プロフィールページからメールレターに登録してやって下さいー。
                      </p>
                      <p class="has-text-centered">
                        <a href="https://techfree.jp/profile" target="_blank"
                          >プロフィール | TECHFREE</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
